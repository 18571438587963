import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '../onepirate/modules/components/Typography';
import AppFooter from '../onepirate/modules/views/AppFooter';
import AppAppBar from '../onepirate/modules/views/AppAppBar';
import AppForm from '../onepirate/modules/views/AppForm';
import RFTextField from '../onepirate/modules/form/RFTextField';
import FormButton from '../onepirate/modules/form/FormButton';
import FormFeedback from '../onepirate/modules/form/FormFeedback';


// my imports
import { API, Auth } from 'aws-amplify';
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';
import { ToggleButtonGroup } from '@mui/material';

export default function UserDetails(props) {
  const [sent, setSent] = React.useState(false);
  const [isManager, setIsManager] = React.useState(false);

  const validate = (values) => {
    var errors = "";

    errors = 'minimum length is 6';
    errors = ''
    return errors;
  };

  const handleSubmit = async (values) => {
    console.log(`updating user details ${JSON.stringify(values)} and isManager ${isManager}`);
    try {
        setSent(true);
        var path = `users?first=${values.firstname}&last=${values.lastname}&isManager=${isManager}`;
        let userData = await API.post("users", path);
        console.log(`users API returned data ${JSON.stringify(userData)}`)
        if (userData === 'user updated') {
          console.log(`expected results user details`)
          props.updateUser();
          await new Promise(r => setTimeout(r, 1000)); 
          props.updateLocation('paperbase_postUserDetailsCall');
        }
    } catch (e) {
      setSent(false);
      console.log(`error in updating user details`)
      if (e = 'The user is not authenticated') {
        console.log(`error in update user ${e}`);
        this.setState({auth: false, user: undefined});
      } else {
        console.log(`error in update user ${e}`);
      }
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            We need a few details:
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit, submitting }) => (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 6 }}>
              <Field
                autoFocus
                fullWidth
                autoComplete="firstname"
                disabled={submitting || sent}
                component={RFTextField}
                label="First Name"
                margin="normal"
                name="firstname"
                required
                size="large"
              />
              <Field
                type="input"
                fullWidth
                disabled={submitting || sent}
                component={RFTextField}
                label="Last Name"
                margin="normal"   
                name="lastname"
                required
                size="large"
              />
              <ToggleButtonGroup
                color="secondary"
                exclusive
                aria-label="Platform"
              >
                <ToggleButton
                  value="check"
                  selected={isManager}
                  onChange={() => {
                    setIsManager(!isManager);
                  }}
                >
                  <Typography>Press, if your a manager</Typography>
                  {
                   isManager ? <CheckIcon /> : null
                  }
                </ToggleButton>
              </ToggleButtonGroup>
              
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting || sent ? 'In progress…' : 'Get Started'}
              </FormButton>
            </Box>
          )}
        </Form>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

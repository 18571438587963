import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Field, Form, FormSpy } from 'react-final-form';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import { email, required } from './modules/form/validation';
import RFTextField from './modules/form/RFTextField';
import FormButton from './modules/form/FormButton';
import FormFeedback from './modules/form/FormFeedback';
import withRoot from './modules/withRoot';


// my imports
import { Auth } from 'aws-amplify';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useEffect } from 'react';
import axios from "axios";

import titles from '../titles.json';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function SignUp(props) {
  const [sent, setSent] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [ip, setIP] = React.useState("");

  const [openOneTimeModal, setOneTimeModaleOpen] = React.useState(false);
  const handleOneTimeModalClose = () => setOneTimeModaleOpen(false);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
    console.log(`set IP ${ip}`);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }
    if (!errors.password) {
      if (values.password !== undefined) {
        if(values.password.length < 5) {
          errors.password = 'minimum length is 6';
        }
      }
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    if(!terms) {
      alert('please accept terms');
    } else {
      console.log(`values ${JSON.stringify(values)}`)
      // create user
      var username = values.email.split('@')[0].concat(':').concat(Math.floor(Math.random() * 10));
      console.log(`values ${JSON.stringify(values)} and username ${username} lowerCase ${username.toLowerCase()}`);
      var today = new Date().toLocaleDateString('en-us', {  month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true});
      console.log(`todays date ${today}`);
      console.log(`users IP ${ip}`);
      
      try {
        const newUser = await Auth.signUp({
          username: username.toLowerCase(),
          password: values.password,
          attributes: {
              email: values.email,
              phone_number: `+14161234567`,
              'custom:termsConditionsDate': today,
              'custom:termsConditionsIP': ip
          }
        });
        console.log('account created');
        if (!!newUser) {
          alert(`Check your inbox (or potentially your junk folder) and verify your email address`);

        } else {
          alert(`some issue encountered. Contact us info@mycondico.com`);
        }
        setSent(true);
        props.updateLocation('signin');
      } catch (e) {
        alert(`some issue encountered. ${e}`);
        console.log(`error on sign up ${e}`);
      }
    }
  };

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link onClick={() => props.updateLocation('signin')} underline="always">
              Already have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <Modal
          open={openOneTimeModal}
          onClose={handleOneTimeModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            {
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Terms and Conditions
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {titles.app.general.terms}
                </Typography>
                <Button onClick={() => {  console.log(`agree`); handleOneTimeModalClose(); }}>Close</Button>
              </div>
            }
          </Box>
        </Modal>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="password"
                autoComplete="new-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormControlLabel size="sm" sx={{ width: 400 }}
                label={<Typography level="body-sm">
                  I have read and agree to the <Link onClick={()=>setOneTimeModaleOpen(true)}>terms and conditions</Link>.
                </Typography>}
                control={<Checkbox onChange={(event)=>{console.log(`checkbox event ${event.target.checked}`); setTerms(event.target.checked)}}/>}
              />
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent || !terms}
                color="secondary"
                fullWidth
              >
                {submitting || sent ? 'In progress…' : 'Sign Up'}
              </FormButton>
              
            </Box>
          )}
        </Form>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(SignUp);

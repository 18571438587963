import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

// my imports
import SignUp from '../../SignUp';

import titles from '../../../titles.json';

const backgroundImage =
  'https://images.unsplash.com/photo-1689163350965-2e85756a994e?q=80&w=3542&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

export default function ProductHero(props) {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        {titles.app.landingPage.sectionA.first}
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        {titles.app.landingPage.sectionA.second}
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        //href="/premium-themes/onepirate/sign-up/"
        onClick={() => props.updateLocation('signup')}
        sx={{ minWidth: 200 }}
      >
        Register
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
      {titles.app.landingPage.sectionA.third}
      </Typography>
    </ProductHeroLayout>
  );
}

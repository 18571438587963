import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';


// my imports
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import ReplayIcon from '@mui/icons-material/Replay';
import PaymentIcon from '@mui/icons-material/Payment';

import titles from '../../../titles.json';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src=""
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <SmartDisplayIcon fontSize="large"/> 
              <Typography variant="h6" sx={{ my: 5 }}>
              {titles.app.landingPage.sectionB.first.title}
              </Typography>
              <Typography variant="h5">
              {titles.app.landingPage.sectionB.first.subTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
            <ReplayIcon fontSize="large"/> 
              <Typography variant="h6" sx={{ my: 5 }}>
              {titles.app.landingPage.sectionB.second.title}
              </Typography>
              <Typography variant="h5">
                  {titles.app.landingPage.sectionB.second.subTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
            <PaymentIcon fontSize="large"/> 
              <Typography variant="h6" sx={{ my: 5 }}>
              {titles.app.landingPage.sectionB.third.title}
              </Typography>
              <Typography variant="h5">
              {titles.app.landingPage.sectionB.third.subTitle}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;

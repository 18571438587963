import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import SetupForm from './SetupForm';

import config from '../config';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function PaymentMethods(props) {
  const options = {
    // passing the SetupIntent's client secret
    clientSecret: props.secret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };

  return (
    <Elements stripe={props.stripePromise} options={{clientSecret: props.secret}} onChange={console.log(`changed ELEMENTS`)}>
      <SetupForm releaseModal={() => props.releaseModalFromCCView()}/>
    </Elements>
  );
};
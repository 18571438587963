import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports
import { API } from 'aws-amplify';
import VideoEngineShaka from '../videoEngineShaka.js';
import Cameras from './Cameras.js';
import ContactUs from '../More/ContactUs.js';
import Memberships from './Memberships.js';
import Playback from './Playback.js';

import titles from '../titles.json';
import Profile from '../More/Profile.js';

export default function Content(props) {
  //console.log(`props in content is ${JSON.stringify(props)}`);
  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Grid item>
              {
                props.leftNavSelectedId === titles.app.services.leftNavigatorTabs[0] ? 
                  <Memberships updateUser={props.updateUser} sub={props.sub} userData={props.userData} topNavSelectedId={props.membershipsTopNavSelectedId} /> : 
                props.leftNavSelectedId === titles.app.services.leftNavigatorTabs[1] ?
                  <Cameras updateUser={props.updateUser} sub={props.sub} userData={props.userData} topNavSelectedId={props.camerasTopNavSelectedId} /> : 
                props.leftNavSelectedId === titles.app.services.leftNavigatorTabs[2] ?
                  <Playback updateUser={props.updateUser} sub={props.sub} userData={props.userData} topNavSelectedId={props.playbackTopNavSelectedId} /> : 
                props.leftNavSelectedId === titles.app.more.leftNavigatorTabs[1] ?
                  <ContactUs /> :
                props.leftNavSelectedId === titles.app.more.leftNavigatorTabs[0] ?
                  <Profile updateUser={props.updateUser} userAttributes={props.userAttributes} sub={props.sub} userData={props.userData} /> :
                null
              }
            </Grid>
      </AppBar>
    </Paper>
  );
}

// This function mainly handles navigation

import * as React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';

// my imports
import SignUp from './SignUp';
import SignIn from './SignIn';
import Paperbase from '../paperbase/Paperbase';
import ForgotPassword from './ForgotPassword';
import { Auth } from 'aws-amplify';
import Typography from './modules/components/Typography';

import { useEffect } from 'react';
import UserDetails from '../CollectInfo/UserDetails';

function Index(props) {
  const [location, setlocation] = React.useState('default');

  useEffect(() => {
    console.log(`useEffect in home `);
  });

  const updateLocation = (path) => {
    console.log(`setlocation updating path ${path}`);
    console.log(`props at home ${JSON.stringify(props)}`);

    // first login/gather details check
    

    if (path === 'signup' || path === 'signin') {
      console.log('path is signup / sign in');
    }
    if (path === 'default') {
      props.logoff();
      setlocation('default');
      console.log('signing out');
    } else if (path === 'paperbase' || path === 'userDetails') {
      console.log(` calling update user to app.js`);
      props.updateUser();
      setlocation(path);
    } else if (path === 'paperbase_postUserDetailsCall') {
      setlocation('paperbase');
    } else {
      setlocation(path);
    }
  }

  // below is our navigation. Note: we use props check only for browser refresh otherwise functions hold up and authenticated state holds up accross navigation
  return (
    <React.Fragment>
      <AppAppBar authenticated={props.authenticated || location === 'paperbase' ? true : false} updateLocation={updateLocation} />
      {
        location === 'userDetails' ? 
          <UserDetails {...props} updateLocation={updateLocation} /> : 
        (props.authenticated || location === 'paperbase') && props.userData.firstname !==undefined ? 
          <Paperbase {...props} /> :
        (props.authenticated || location === 'paperbase') ?
          <UserDetails {...props} updateLocation={updateLocation} /> :
        location === 'default' ?
          <div>
            <ProductHero updateLocation={updateLocation} />
            <ProductValues />
            <ProductHowItWorks updateLocation={updateLocation} />
            <ProductSmokingHero />
          </div> : 
        location === 'signup' ?
          <SignUp {...props} updateLocation={updateLocation} /> : 
        location === 'signin' ?
          <SignIn {...props} updateLocation={updateLocation} /> :
        location === 'forgotpassword' ?
          <ForgotPassword {...props} updateLocation={updateLocation} /> :
          <Typography>Loading...</Typography>
      }
      {location === 'paperbase' || location === 'signup' || location === 'signin' || location === 'forgotpassword' ? null : <AppFooter />}
    </React.Fragment>
  );
}

export default withRoot(Index);

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports
import { Autocomplete } from '@mui/material';
import { Auth, API } from 'aws-amplify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';

import titles from '../titles.json';

import { useEffect } from 'react';

import Box from '@mui/material/Box';
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ManagerClubMembers(props) {
  const [searchedClubSelection, setClubSelection] = React.useState('');
  const [membership, setMembership] = React.useState('');
  const [userData, setUserData] = React.useState(undefined);
   //can add async events in useEffects for APIs
  
  
  const [loading, setLoading] = React.useState(false);
  const [fullMemberList, setFullMemberList] = React.useState(undefined);
  const [clubInfo, setClubInfo] = React.useState(undefined);

  const [approvedMembers, setApprovedMembers] = React.useState(undefined);
  const [selectedApprovedUser, setSelectedApprovedUser] = React.useState(undefined);
 
  const [pendingMembers, setPendingMembers] = React.useState(undefined);
  const [selectedPendingUser, setSelectedPendingUser] = React.useState(undefined);

  const [othersMembers, setOthersMembers] = React.useState(undefined);

   useEffect(() => {
    
    console.log(`useEffect in ManagerClubMembers with props ${JSON.stringify(props)}`);
    
    // add API to get userData (if not already in props aand clubData. Not yet here.
      // {fill in}
    // then create clubsList for clubs tab that user is approved / pending for
    if(loading) {
      return undefined
    }
    setLoading(true);

    (async () => {
      console.log('MANAGERS MEMBERS')
      let club = await getClubInfo();
      if (club.length > 0) {
        setClubInfo(club);
        cycleThroughMemberListToSetMemberships(club[0].members);
      } else {
        setClubInfo([]);
      }
      
      console.log(` API sresponse members data is ${JSON.stringify(club)}`);
      
    })();

    return () => {
      let a = null;
    };
  }, [loading]);

  const getClubInfo = async event => {
    try {
      console.log("getting member list...");
      var path = `clubs?manager=true&type=memberList`;
      let club = await API.get("clubs", path);
      console.log(`got full list of members in club ${JSON.stringify(club)} and now about to check stateOfMembership on with props userData ${JSON.stringify(props.userData)}`);
      return club;
    } catch (e) {
      console.log("clubData error is: ", e);
        alert(e.message);
    }
  }

  const cycleThroughMemberListToSetMemberships = async (fullMemberList) => {
    var approvedList = [];
    var pendingList = [];
    var othersList = [];
    let i = 0;
    //const localUserData = await API.get("users", 'users');
    console.log(`have full memebers list ${JSON.stringify(fullMemberList)}`);
    while (i < fullMemberList.length) {
      var newItem = {firstname: fullMemberList[i].firstname, lastname: fullMemberList[i].lastname, userId: fullMemberList[i].userId, state: fullMemberList[i].state};
      if (fullMemberList[i].state === 'approved') {
        console.log(`found approved state`);
        approvedList.push(newItem)  
      } else if (fullMemberList[i].state === 'pending') {
        console.log(`found pending state`);
        pendingList.push(newItem);
      } else {
        console.log(`found revoked or denied state`);
        othersList.push(newItem)  
      }
      i++;
    }
    console.log(`set club list called ${approvedList}`);
    
    setFullMemberList(fullMemberList);
    setApprovedMembers(approvedList);
    setPendingMembers(pendingList);
    setOthersMembers(othersList);
  }

  const switchApprovedUser = (e) => {
    console.log(`switching approved user 2${e.event.target.value}`);
      let user = {userId: e.event.target.value, name: e.name}
      setSelectedApprovedUser(user);
    
  }

  const switchPendingUser = (e) => {
    console.log(`switching pending user 2${e.event.target.value}`);
      let user = {userId: e.event.target.value, name: e.name}
      setSelectedPendingUser(user);
    
  }

  const revokeUserFromClub = async (userClub) => {
    try {
      console.log(`revoke user ${JSON.stringify(userClub)}`);
      var path = `clubs?manager=true&type=revoke&userId=${userClub.user.userId}&clubId=${userClub.club.clubId}&clubName=${userClub.club.clubName}`;
      let response = await API.get("clubs", path);
      if (!!response) {
        console.log(`respone data is ${JSON.stringify(response)}`);
        //setApprovedMembers(undefined);
        setSelectedApprovedUser(undefined);
        var i = 0;
        var newApprovedList = approvedMembers;
        var newOthersList = othersMembers;
        var found = false;
        console.log(JSON.stringify(approvedMembers))
        while (i < approvedMembers.length && !found) {
          if (approvedMembers[i].userId === userClub.user.userId) {
            console.log(`found user in approved list to remove`);
            newOthersList.push(approvedMembers[i]);
            newApprovedList.splice(i,1);
            found = true;
          }
          i++;
        }
        console.log(JSON.stringify(approvedMembers));
        setOthersMembers(newOthersList);
        setApprovedMembers(newApprovedList);
        alert(`user access has been removed`);
        return response;
      } else {
        console.log("response came back empty");
      }
    } catch (e) {
      console.log("revokeUser error is: ", e);
        alert(e.message);
    }
  }

  const denyUserFromClub = async (userClub) => {
    try {
      console.log(`deny user ${JSON.stringify(userClub)}`);
      var path = `clubs?manager=true&type=deny&userId=${userClub.user.userId}&clubId=${userClub.club.clubId}&clubName=${userClub.club.clubName}`;
      let response = await API.get("clubs", path);
      if (!!response) {
        console.log(`respone data is ${JSON.stringify(response)}`);
        //setApprovedMembers(undefined);
        setSelectedPendingUser(undefined);
        var i = 0;
        var newPendingList = pendingMembers;
        var newOthersList = othersMembers;
        var found = false;
        console.log(JSON.stringify(pendingMembers))
        while (i < pendingMembers.length && !found) {
          if (pendingMembers[i].userId === userClub.user.userId) {
            console.log(`found user in pending list to remove`);
            newOthersList.push(pendingMembers[i]);
            newPendingList.splice(i,1);
            found = true;
          }
          i++;
        }
        console.log(JSON.stringify(pendingMembers));
        setOthersMembers(newOthersList);
        setPendingMembers(newPendingList);
        alert(`user access has been denied`);
        return response;
      } else {
        console.log("response came back empty");
      }
    } catch (e) {
      console.log("denyUser error is: ", e);
        alert(e.message);
    }
  }

  const approveUserFromClub = async (userClub) => {
    try {
      console.log(`approve user ${JSON.stringify(userClub)}`);
      var path = `clubs?manager=true&type=approve&userId=${userClub.user.userId}&clubId=${userClub.club.clubId}&clubName=${userClub.club.clubName}`;
      let response = await API.get("clubs", path);
      if (!!response) {
        console.log(`respone data is ${JSON.stringify(response)}`);
        //setApprovedMembers(undefined);
        setSelectedPendingUser(undefined);
        var i = 0;
        var newPendingList = pendingMembers;
        var newApprovedList = approvedMembers;
        var found = false;
        console.log(JSON.stringify(pendingMembers));
        console.log(JSON.stringify(approvedMembers));
        while (i < pendingMembers.length && !found) {
          if (pendingMembers[i].userId === userClub.user.userId) {
            console.log(`found user in pending list to remove`);
            newApprovedList.push(pendingMembers[i]); // verify this working????
            newPendingList.splice(i,1);
            found = true;
          }
          i++;
        }
        console.log(JSON.stringify(pendingMembers));
        console.log(JSON.stringify(approvedMembers));
        setPendingMembers(newPendingList);
        setApprovedMembers(newApprovedList);
        alert(`user access has been approved`);
        return response;
      } else {
        console.log("response came back empty");
      }
    } catch (e) {
      console.log("approveUser error is: ", e);
        alert(e.message);
    }
  }

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        { 
          clubInfo === undefined ? 
            <Box component="main" sx={{ p: 3 }}>
              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                Loading...
              </Typography>
            </Box> :
          clubInfo.length === 0 ? 
            <Box component="main" sx={{ p: 3 }}>
              <Typography display="inline" sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                <Typography display="inline" color="error" align="left">Email info@mycondico.com</Typography>{titles.manager.members.requestClub} 
              </Typography>
            </Box> :
            <Box component="main" sx={{ p: 3 }}>
              {
                    props.topNavSelectedId === titles.app.services.manager.topNavigatorTabs.members[0] ?
                      <div>
                          {
                              approvedMembers !== undefined && fullMemberList !== undefined ?
                              <div>
                              {
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                  >
                                    {approvedMembers.map(({ firstname, lastname, userId}) => (
                                      <FormControlLabel
                                        value={userId} 
                                        control={
                                          <Radio 
                                            onChange={(e) => switchApprovedUser({event: e, name: `${firstname} ${lastname}`})}
                                            />
                                          } 
                                        label={
                                          <Grid container spacing={2} alignItems="center">
                                              <Grid item xs>
                                                <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                    {firstname}, {lastname}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs>
                                                  <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                      
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs>
                                                  <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                      <CheckCircleOutlineIcon color="success" sx={{ display: 'block' }} />
                                                  </Typography>
                                              </Grid>
                                        </Grid>
                                        }
                                      />
                                    ))}
                                  </RadioGroup>
                              }
                            </div> : 
                            null
                          }
                          {
                            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                              {
                                selectedApprovedUser !== undefined ? <Button variant="outlined" color="warning" onClick={() => { revokeUserFromClub({user: selectedApprovedUser, club: {clubId: clubInfo[0].clubId, clubName: clubInfo[0].clubName}})}}>{ titles.manager.members.approved.revokeBtn}</Button> : null
                              }
                          </Typography>
                          }
                      </div> :
                      props.topNavSelectedId === titles.app.services.manager.topNavigatorTabs.members[1] ?
                      <div>
                          {
                              pendingMembers !== undefined && fullMemberList !== undefined ?
                              <div>
                              {
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                  >
                                    {pendingMembers.map(({ firstname, lastname, userId}) => (
                                      <FormControlLabel
                                        value={userId} 
                                        control={
                                          <Radio 
                                            onChange={(e) => switchPendingUser({event: e, name: `${firstname} ${lastname}`})}
                                            />
                                          } 
                                        label={
                                          <Grid container spacing={2} alignItems="center">
                                              <Grid item xs>
                                                <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                    {firstname}, {lastname}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs>
                                                  <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                      
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs>
                                                  <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                      <PauseCircleFilledIcon color="warning" sx={{ display: 'block' }} />
                                                  </Typography>
                                              </Grid>
                                        </Grid>
                                        }
                                      />
                                    ))}
                                  </RadioGroup>
                              }
                            </div> : 
                            null
                          }
                          {
                            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                              {
                                selectedPendingUser !== undefined ?
                                <div>
                                  <Button variant="outlined" color="warning" onClick={() => { denyUserFromClub({user: selectedPendingUser, club: {clubId: clubInfo[0].clubId, clubName: clubInfo[0].clubName}})}}>{ titles.manager.members.pending.denyBtn}
                                  </Button>
                                  <Button variant="outlined" color="success" onClick={() => { approveUserFromClub({user: selectedPendingUser, club: {clubId: clubInfo[0].clubId, clubName: clubInfo[0].clubName}})}}>{ titles.manager.members.pending.aproveBtn}
                                  </Button>
                                </div>
                                : 
                                null
                              }
                          </Typography>
                          }
                      </div>
                      :
                      props.topNavSelectedId === titles.app.services.manager.topNavigatorTabs.members[2] ?
                        <div>
                          {
                              othersMembers !== undefined && fullMemberList !== undefined ?
                              <div>
                              {
                                  othersMembers.map(({ firstname, lastname, userId, state}) => (
                                    <Grid container spacing={2} alignItems="center">
                                          <Grid item xs>
                                            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                {firstname}, {lastname}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs>
                                              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                  
                                              </Typography>
                                          </Grid>
                                          <Grid item xs>
                                              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                                  {
                                                    state === 'denied' ?
                                                    <DoDisturbIcon color="error" sx={{ display: 'block' }} /> :
                                                    state === 'revoked' ?
                                                    <DoNotDisturbOffIcon color="error" sx={{ display: 'block' }} /> :
                                                    null
                                                  }
                                              </Typography>
                                          </Grid>
                                    </Grid>
                                  ))
                              }
                            </div> : 
                            null
                          }
                          {
                            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                              {
                                selectedPendingUser !== undefined ?
                                <div>
                                  <Button variant="outlined" color="warning" onClick={() => { denyUserFromClub({user: selectedPendingUser, club: {clubId: clubInfo[0].clubId, clubName: clubInfo[0].clubName}})}}>{ titles.manager.members.pending.denyBtn}
                                  </Button>
                                  <Button variant="outlined" color="success" onClick={() => { approveUserFromClub({user: selectedPendingUser, club: {clubId: clubInfo[0].clubId, clubName: clubInfo[0].clubName}})}}>{ titles.manager.members.pending.aproveBtn}
                                  </Button>
                                </div>
                                : 
                                null
                              }
                          </Typography>
                          }
                      </div> :
                      null
                }
            </Box>
          }
      </AppBar>
      <Toolbar>
        {
          props.topNavSelectedId === titles.app.services.manager.topNavigatorTabs.members[0] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.manager.members.approved.general} 
            {
              clubInfo !== undefined && clubInfo.length > 0 ? <Typography variant='h6'>to: {clubInfo[0].clubName}</Typography> : null
            }
          </Typography> :
          props.topNavSelectedId === titles.app.services.manager.topNavigatorTabs.members[1] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.manager.members.pending.general} 
            {
              clubInfo !== undefined && clubInfo.length > 0 ? <Typography variant='h6'>to: {clubInfo[0].clubName}</Typography> : null
            }
          </Typography> 
          :
          props.topNavSelectedId === titles.app.services.manager.topNavigatorTabs.members[2] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.manager.members.others.general} 
            {
              clubInfo !== undefined && clubInfo.length > 0 ? <Typography variant='h6'>to: {clubInfo[0].clubName}</Typography> : null
            }
          </Typography> 
          :
          null    
        }
      </Toolbar>
    </Paper>
  );
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports
import { Autocomplete } from '@mui/material';
import { Auth, API } from 'aws-amplify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import titles from '../titles.json';

import { useEffect } from 'react';

import Box from '@mui/material/Box';

export default function Memberships(props) {
  const [searchedClubSelection, setClubSelection] = React.useState('');
  const [membership, setMembership] = React.useState('');
  const [userData, setUserData] = React.useState(undefined);
   //can add async events in useEffects for APIs
  const [loading, setLoading] = React.useState(false);
  const [clubListRequested, setClubListRequested] = React.useState(undefined);
  const [fullClubList, setFullClubList] = React.useState(undefined);

  useEffect(() => {
    console.log(`useEffect in memberships props.userData ${JSON.stringify(props.userData)} `);
    setUserData(props.userData);
  }, [props]);

   useEffect(() => {
    
    //console.log(`useEffect in memberships with props ${JSON.stringify(props)}`);
    
    // add API to get userData (if not already in props aand clubData. Not yet here.
      // {fill in}
    // then create clubsList for clubs tab that user is approved / pending for
    if(loading) {
      return undefined
    }
    setLoading(true);

    (async () => {
      console.log('BBBBBBBB')
      let fullClubList = await getClubList();
      cycleThroughClubListToSetMemberships(fullClubList);
      console.log(`BBBBBBBBBBBB API sresponse club data is ${JSON.stringify(fullClubList)}`);
      
    })();

    return () => {
      let a = null;
    };
  }, [loading]);

  const getClubList = async event => {
    try {
      console.log("getting club list...");
      var path = `clubs?key1=na`;
      let fullClubList = await API.get("clubs", path);
      console.log(`got full list of clubs and now about to check stateOfMembership on with props userData ${JSON.stringify(props.userData)}`);
      return fullClubList;
    } catch (e) {
      console.log("clubData error is: ", e);
      alert(e.message);
    }
  }

  const cycleThroughClubListToSetMemberships = async (fullClubList) => {
    var list = [];
    let i = 0;
    const localUserData = await API.get("users", 'users');
    console.log(`localUserData retrieved is ${JSON.stringify(localUserData)}`);
    while (i < fullClubList.length) {
      var memberState = stateOfMembership({id: fullClubList[i].clubId, label: fullClubList[i].clubName}, localUserData);
      if (memberState !== '') {
        var newItem = {clubId: fullClubList[i].clubId, clubName: fullClubList[i].clubName, address: fullClubList[i].address, phone: fullClubList[i].phone, isMember: memberState};
        list.push(newItem)  
      }
      i++;
    }
    console.log(`set club list called ${list}`);
    setClubListRequested(list);
    setFullClubList(fullClubList);
  }

  // value is {id, label} where label is name of club and id is clubId
  // clubInfo is {id: clubId, label: club name} where label is name of club and id is clubId. we go through users data to find the club id from their clubs array to determine state. if not present than has not requested access and we show a button.
  const handleClubSearchTextFieldChange = (value) => {
    console.log(`club search changed ${JSON.stringify(value)}`);
    setClubSelection(value);
    setMembership(stateOfMembership(value, props.userData));
  }

  const requestMembership = async (searchedClub) => {
  
    setClubSelection('');

    console.log(`requesting membership for ${JSON.stringify(searchedClubSelection)} where user data is ${JSON.stringify(userData)}`);
    try {
      console.log("requesting new membership access to club ...");
      var path = `clubs?clubId=${searchedClub.id}&clubName=${searchedClub.label}&type=new&firstname=${props.userData.firstname}&lastname=${props.userData.lastname}`;
      let status = await API.post("clubs", path);
      props.updateUser();
      // between updateUser and getClubList appears userData is not propagated here??? why. API we saw returned pending club 1 i.e. toronto athletic club

      let fullClubList = await getClubList();
      console.log(`full club list of user returned`);
      if (!!status) {
        console.log(`status of membership request ${JSON.stringify(status)}`);
        await new Promise(r => setTimeout(r, 1000)); 
        cycleThroughClubListToSetMemberships(fullClubList);
        alert(`request sent to ${searchedClub.managersName}`);
      } else {
        console.log("status came back empty");
      }
    } catch (e) {
      console.log("status error is: ", e);
        alert(e.message);
    }
  }

  const makeClubsDropDownFromAllCLubsData = (clubData) => {
    let i = 0;
    var dropDown = [];
    console.log(`make drop down input ${JSON.stringify(clubData)}`)
    while (i < clubData.length) {
      var id = clubData[i].clubId;
      var label = clubData[i].clubName;
      var managersName = clubData[i].managersName;
      var newItem = {id: id, label: label, managersName: managersName};
      dropDown.push(newItem)
      i++;
    }
    return dropDown;
  }

   // clubInfo is {id: clubId, label: club name} where label is name of club and id is clubId. we go through users data to find the club id from their clubs array to determine state. if not present than has not requested access and we show a button.
   const stateOfMembership = (clubInfo, localUserData) => {
    
    console.log(`doing stateOfMembership check on ${JSON.stringify(clubInfo)}`);
    var result = ''; // result could be '', approve, pending where '' means not yet requested
    let i = 0;
    console.log(`MEMBERSHIPS.sj - checking is member of club ${JSON.stringify(clubInfo)} with userData ${JSON.stringify(userData)}`);

    while (i < localUserData.clubs.approved.length) {
      if (localUserData.clubs.approved[i] === clubInfo.id) {
        result = 'approved' 
      }
      i++;
    }
    if(result === '') {
      i = 0;
      while (i < localUserData.clubs.pending.length) {
        if (localUserData.clubs.pending[i] === clubInfo.id) {
          result = 'pending' 
        }
        i++;
      }
    }
    return result;
  }

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Box component="main" sx={{ p: 3 }}>
          {
              props.topNavSelectedId === titles.app.services.topNavigatorTabs.memberships[0] ?
                  <div>
                      {
                          clubListRequested === undefined ?
                          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                              Loading ...
                          </Typography> :
                          clubListRequested.length > 0 && fullClubList !== undefined ?
                          <div>
                          {
                            clubListRequested.map(({ clubName, address, phone, isMember}) => (
                              <Grid container spacing={2} alignItems="center">
                                  <Grid item xs>
                                      <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                          {clubName}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs>
                                      <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                          {address}, {phone}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs>
                                      <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                          { isMember === '' ? 'not requested' : isMember }
                                          {
                                            isMember === 'approved' ? 
                                            <CheckCircleOutlineIcon color="success" sx={{ display: 'block' }} /> :
                                            isMember === 'pending' ? 
                                            <PauseCircleFilledIcon color="warning" sx={{ display: 'block' }} /> :
                                            null
                                          }
                                      </Typography>
                                  </Grid>
                              </Grid>
                            ))
                          }
                          </div> : 
                          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                            {titles.app.general.empty}
                          </Typography>
                      }
                  </div> :
                  props.topNavSelectedId === titles.app.services.topNavigatorTabs.memberships[1] ?
                  <Grid container spacing={2} alignItems="center">
                      <Grid item>
                          <SearchIcon color="inherit" sx={{ display: 'block' }} />
                      </Grid>
                      <Grid item xs>
                        {
                          fullClubList !== undefined ? 
                          <Autocomplete
                            disablePortal
                            disableClearable
                            id="club-selection"
                            options={makeClubsDropDownFromAllCLubsData(fullClubList)}
                            onChange={(event, value) => {console.log(`on change value ${JSON.stringify(value)}`); handleClubSearchTextFieldChange(value) }}
                            renderInput={(params) => <TextField variant="standard" fullWidth {...params} label="Club Name"  />}
                          /> : 
                          <Typography>...</Typography>
                        }
                        
                      </Grid>
                  </Grid> :
                  null
          }
        </Box>
      </AppBar>
      <Toolbar>
        {
          props.topNavSelectedId === titles.app.services.topNavigatorTabs.memberships[0] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.memberships.clubs.general}
          </Typography> :
          searchedClubSelection.label === '' ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.memberships.subscribe.general}
          </Typography> :
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {
                membership === 'approved' ? 
                <CheckCircleOutlineIcon color="success" sx={{ display: 'block' }} /> :
                membership === 'pending' ?
                <PauseCircleFilledIcon color="warning" sx={{ display: 'block' }} /> :
                <Button onClick={() => {
                    requestMembership(searchedClubSelection); 
                    setClubListRequested(undefined);
                    setFullClubList(undefined);
                  }}>
                  { titles.memberships.subscribe.requestButton}
                </Button>
              }
            </Grid>
            <Grid item>
              {
                membership === 'approved' ? 
                <Typography>{ titles.memberships.subscribe.approved }</Typography> :
                membership === 'pending' ?
                <Typography>{ titles.memberships.subscribe.pending }</Typography> :
                <Typography>{ titles.memberships.subscribe.request }</Typography>
              }
            </Grid>
            <Grid item xs>
              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
                {searchedClubSelection.label}
              </Typography>
            </Grid>
          </Grid>
        }
      </Toolbar>
    </Paper>
  );
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports

import CallIcon from '@mui/icons-material/Call';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FaceIcon from '@mui/icons-material/Face';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import titles from '../titles.json';

import { useEffect } from 'react';
import { API } from 'aws-amplify';

export default function Profile(props) {
  const [loading, setLoading] = React.useState(false);
  const [info, setInfo] = React.useState(undefined);
  //const [subscriptionInfo, setSubscriptionInfo] = React.useEffect('');

  useEffect(() => {
    console.log(`obtaining subscription and payment CC info where props are ${JSON.stringify(props)}`);
    if(loading) {
      return undefined
    }
    setLoading(true);

    (async () => {
      let info = await getInfo();
      if (info === 'empty info') {
        return;
      } else if (info.subscription !== undefined) {
          setInfo(info);
      }
    })();

    return () => {
      let a = null;
    };
  }, [loading]);

  const getInfo = async event => {
    try {
      if(props.userData.type === 'regular') {
        console.log(` getting CC info as regular user`)
        var path;
        path = `payments?type=info`;
        console.log(`getting info with path ${path}`);
        let response = await API.post("payments", path);
        console.log(`result ${JSON.stringify(response)}`);
        if (!!response) {
          console.log(`response is ${JSON.stringify(response)}`);
          return response;
        } else {
          console.log("response info came back empty");
        }
      } else {
        return 'empty info';
      }
    } catch (e) {
      console.log("clubData error is: ", e);
        alert(e.message);
    }
  }

  const cancelSubscription = async () => {

    if (window.confirm(`Are you sure you want to cancel? You will lose the days between now and your renewal ${timeConverter(info.subscription.renewal_date)} as this will take effect immediately.`)) {
      console.log('cancelling subscription');
      try {
        var path;
        path = `payments?type=subscription&update=cancel`;
        console.log(`cancelling subscription with path ${path}`);
        let response = await API.post("payments", path);
        console.log(`result ${JSON.stringify(response)}`);
        if (!!response) {
          console.log(`response is ${JSON.stringify(response)}`);
          alert('Successfully completed. To resubscribe select a camera to record.');
          setInfo(undefined);
          props.updateUser();
        } else {
          console.log("response info came back empty");
        }
      } catch (e) {
        console.log("response error is: ", e);
          alert(e.message);
      }
      console.log('cancelled');
    } else {
      // Do nothing!
      console.log('Thing was not canceled.');
    }
    
    
  }

  const timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year  ;
    return time;
  }

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4} >
                  <FaceIcon /> 
                </Grid>
                <Grid item xs={6}>
                  <Typography>{props.userData.firstname}, {props.userData.lastname}</Typography>
                </Grid>
            </Grid>
        </Toolbar>
        <Toolbar>
            <Grid container spacing={2} alignItems="center" >
                <Grid item xs={4}>
                  <EmailIcon /> 
                </Grid>
                <Grid item xs={4}>
                  <Typography>{props.userAttributes.email}</Typography>
                </Grid>
            </Grid>
        </Toolbar>
        { info !== undefined ? 
          <div>
            <Toolbar>
                <Grid container spacing={2} alignItems="center" >
                    <Grid item xs={4}>
                      <CreditCardIcon /> 
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>****{info.card.last4} {info.card.brand}</Typography>
                    </Grid>
                </Grid>
            </Toolbar>
            <Toolbar>
                <Grid container spacing={2} alignItems="center" >
                    <Grid item xs={4}>
                      <LoyaltyIcon /> 
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>subscription renews on {timeConverter(info.subscription.renewal_date)}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant="outlined" onClick={() => {  console.log(`yes`); cancelSubscription();}}>Cancel Subscription and remove credit card on file</Button>
                    </Grid>
                    <Grid item xs={4}>
                      
                    </Grid>
                    <Grid item xs={12}>
                      
                    </Grid>
                </Grid>
            </Toolbar>
          </div> :
            null
        }
      </AppBar>
    </Paper>
  );
}

/* instructions: 
1) just change default Environment setting between development & production. 
2) select the correct indexHtmlOption and copy / paste over index.html
*reference: https://www.sistrix.com/ask-sistrix/google-index-google-bot-crawler/how-can-i-remove-a-url-on-my-website-from-the-google-index/#Option_1_Use_the_robots_meta-element_with_NOINDEX
* Ensure that if Terms has been updated that we increment the version # inside both TermsAndConditionsAuth.js and TermsAndConditions.js
*/
export default Object.freeze({
    Environment: "production",
    ThemeColor: '#8ACF2E',
    ThemeColorRgba: 'rgba(138,207,46,0.3)',
    ThemeColorRgb: 'rgb(138,207,46)',
    LightGreyColor: '#D6D6D6',
    DarkGreyColor: '#a9a9a9',
    VeryDarkGreyColor: '#A0A0A0',
    NavBarColor: 'white',
    getEnvironmentConfig: getEnvironmentConfig,
});

function getEnvironmentConfig(env) {
  //utilityFunctions.log(`env config is ${JSON.stringify(environmentConfig[env])}`)
  return environmentConfig[env];
}

const environmentConfig = {
    production: {
      environment:"production",
      maxDatePlayBack: 7,
      debug: false,
      stripePublishableKey: "",
      subscriptionFee: 10,
      playbackFee: 4,
      apiGatewayClubs: {
        REGION: "us-east-1",
        URL: "https://akyc8rllaf.execute-api.us-east-1.amazonaws.com/Production/"
      },
      apiGatewayUsers: {
        REGION: "us-east-1",
        URL: "https://2x4snkhl27.execute-api.us-east-1.amazonaws.com/Production/"
      },
      apiGatewayVideos: {
        REGION: "us-east-1",
        URL: "https://mlyiwlwt47.execute-api.us-east-1.amazonaws.com/Production/"
      },
      apiGatewayPayments: {
        REGION: "us-east-1",
        URL: "https://j4w8tsxfaa.execute-api.us-east-1.amazonaws.com/Production/"
      },
      cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_4b9SKtJRv",
        APP_CLIENT_ID: "vjt0lt275da6cled3ks66k63r",
        IDENTITY_POOL_ID: "us-east-1:c7c90ea8-1825-4588-968c-360721e9b09c"
      },
    },
    development: {
      stripePublishableKey: "pk_test_51Pze6QLkwet6xRPOyUNg55RjSTzuYd0HbNJNVRHTRSsIKLMkqR9WrA54JzNeorQr3gYJqOncKitfubZw6A0Hsrg000EgpnhldV"
    }
}
import React from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import Button from '@mui/material/Button';

export default function SetupForm (props) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hideForm, setHideForm] = React.useState(false);

    const subscribe = async (paymentId) => {
        console.log('show cc view');
        try {
          var path;
          console.log(`sending subscription request `);
          path = `payments?paymentId=${paymentId}&type=subscription`;
          console.log(`sending subscribe request with path ${path}`);
          let response = await API.post("payments", path);
          console.log(`result ${JSON.stringify(response)}`);
          if (!!response) {
            console.log(`response is ${JSON.stringify(response)}`);
            alert(`You're now subscribed and can schedule a recording!`);
            console.log(`props in setup are ${JSON.stringify(props)}`);
            props.releaseModal();
          } else {
            console.log("response info came back empty");
          }
        } catch (e) {
          console.log("response error is: ", e);
            alert(e.message);
        }
      }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        try {
            const paymentMethod = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}`,
                    payment_method_data: {
                        allow_redisplay: 'always'
                    }
                },
                redirect: "if_required"
            })
            console.log(`successfully proceessed payment ${JSON.stringify(paymentMethod)}`);
            console.log(`payment id: ${paymentMethod.setupIntent.payment_method}`)
            // get payment card id to send up to subscriptions and customer id
            
            const {error} = paymentMethod;
            //const {error} = confirmation.error.message
            if(error) {
                setMessage(error.message);
                setErrorMessage(error.message);
            } else {
                const subscription = await subscribe(paymentMethod.setupIntent.payment_method);
            }
        } catch (error) {
            setMessage(error.message);
            setErrorMessage(error.message);
        }
        
        setIsProcessing(false);
    }

    return (
        <div>
            {
                !hideForm ?
                    <form id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button disabled={isProcessing} id="submit">
                        <span id="button-text">
                            {isProcessing ? "Processing ..." : "Subscribe now"}
                        </span>
                    </button>
                    {errorMessage && <div>{message}</div>}
                </form> :
                null
            }
        </div>
    );
};
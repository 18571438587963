import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports

import CallIcon from '@mui/icons-material/Call';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import titles from '../titles.json';

import { useEffect } from 'react';

export default function ContactUs(props) {

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4} >
                  <CallIcon /> 
                </Grid>
                <Grid item xs={6}>
                  <Typography>(647) 616-4338</Typography>
                </Grid>
            </Grid>
        </Toolbar>
        <Toolbar>
            <Grid container spacing={2} alignItems="center" >
                <Grid item xs={4}>
                  <EmailIcon /> 
                </Grid>
                <Grid item xs={4}>
                  <Typography>info@mycondico.com</Typography>
                </Grid>
            </Grid>
        </Toolbar>
      </AppBar>
    </Paper>
  );
}

import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import { email, required } from './modules/form/validation';
import RFTextField from './modules/form/RFTextField';
import FormButton from './modules/form/FormButton';
import FormFeedback from './modules/form/FormFeedback';
import withRoot from './modules/withRoot';


// my imports
import { Auth, API } from 'aws-amplify';

function SignIn(props) {
  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }
    if (!errors.password) {
      if (values.password !== undefined) {
        if(values.password.length < 5) {
          errors.password = 'minimum length is 6';
        }
      }
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    console.log(`signing in...`)
    try {
      let user = await Auth.signIn(values.email.toLowerCase(), values.password);
      setSent(true);
      console.log(`user response sign in ${JSON.stringify(user)}`);
      console.log(`signing in...`);
      props.updateUser();
      let userData = await API.get("users", 'users');
      console.log(`received user info ${JSON.stringify(userData)}`);
      var location;
      if (!!userData) {
        console.log('have user details');
        if (!!userData.firstname) {
          console.log(`have first name`);
          location = 'paperbase';
        } else {
          console.log(`missing first name`);
          location = 'userDetails';
        }
      } else {
        location = 'userDetails';
      }
      await new Promise(r => setTimeout(r, 700)); 
      props.updateLocation(location);
    } catch (e) {
      console.log(`sign in error ${JSON.stringify(e)}`)
      alert('Try again')
    }
    //console.log(`login successful ${JSON.stringify(user)}`)
    //Auth.currentSession().then(data => console.log(data));
    
  };

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {'Not a member yet? '}
            <Link
              onClick={() => props.updateLocation('signup')}
              //href="/premium-themes/onepirate/sign-up/"
              align="center"
              underline="always"
            >
              Sign Up here
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit, submitting }) => (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 6 }}>
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting || sent ? 'In progress…' : 'Sign In'}
              </FormButton>
            </Box>
          )}
        </Form>
        <Typography align="center">
          <Link underline="always" onClick={() => props.updateLocation('forgotpassword')}>
            Forgot password?
          </Link>
        </Typography>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(SignIn);

import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Box from '@mui/material/Box';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import { email, required } from './modules/form/validation';
import RFTextField from './modules/form/RFTextField';
import FormButton from './modules/form/FormButton';
import FormFeedback from './modules/form/FormFeedback';
import withRoot from './modules/withRoot';


// my imports
import { Auth } from 'aws-amplify';

function ForgotPassword(props) {
  const [sending, setsending] = React.useState(false);
  const [sent, setsent] = React.useState(false);

  const [sendingCode, setSendingCode] = React.useState(false);
  const [sentCode, setSentCode] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const validateCode = (values) => {
    //const errors = required(['password'], values);
    const errors = {}
    //errors.password = 'mismatched password';
    console.log(`values is ${JSON.stringify(values)} in validation`);
    if (values.password !== values.confirmation) {
      console.log(`password doesnt match`)
      errors.confirmation = 'mismatched password';
    } else {
      console.log('passwords match')
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    setsending(true);
    const link = await Auth.forgotPassword(values.email);
    console.log(`link is ${JSON.stringify(link)}`);
    setsent(true);
  };

  const handleCodeSubmit = async (values) => {
    setsending(true);
    console.log(`vaalues are ${JSON.stringify(values)}`);
    try {
      const reset = await Auth.forgotPasswordSubmit(values.email, values.code, values.confirmation)
      console.log(`link is ${JSON.stringify(reset)}`);
      setsent(true);
      alert('password successfully reset');
      props.updateLocation('signin');
    } catch (e) {
      console.log(`error password reset ${JSON.stringify(e)}`);
    }
    
  };

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            {
              !sent ? 'Forgot your password?' : 'Check your email'
            }
          </Typography>
          <Typography variant="body2" align="center">
            {
              !sent ? 'Enter your email address and we will send you a code to reset.' :
              'Check your email for the code'
            }
          </Typography>
        </React.Fragment>
        {
          sent ?
          <Form
            onSubmit={handleCodeSubmit}
            subscription={{ submitting: true }}
            validate={(values) => validateCode(values)}
            render={({ handleSubmit: handleSubmit2, submitting }) => (
              <Box component="form" onSubmit={handleSubmit2}  sx={{ mt: 6 }}>
                  <Field
                  autoFocus
                  autoComplete="code"
                  component={RFTextField}
                  disabled={submitting || sendingCode}
                  fullWidth
                  label="Code"
                  margin="normal"
                  name="code"
                  required
                  size="large"
                  />
                  <Field
                    fullWidth
                    size="large"
                    component={RFTextField}
                    disabled={submitting || sendingCode}
                    required
                    name="password"
                    autoComplete="current-password"
                    label="Password"
                    type="password"
                    margin="normal"
                  />
                  <Field
                    fullWidth
                    size="large"
                    component={RFTextField}
                    disabled={submitting || sendingCode}
                    required
                    name="confirmation"
                    autoComplete="current-password"
                    label="Confirm Password"
                    type="password"
                    margin="normal"
                  />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sendingCode}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  { sendingCode ? 'In progress…' : 'Reset Password'}
                </FormButton>
              </Box>
            )}
          /> :
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
                {
                  <Field
                  autoFocus
                  autoComplete="email"
                  component={RFTextField}
                  disabled={submitting || sending}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                  />
                }
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sending}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sending ? 'In progress…' : 'Send reset link'}
                </FormButton>
              </Box>
            )}
          </Form>
        }
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(ForgotPassword);

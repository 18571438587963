import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';


import titles from '../titles.json';

import ManagerContent from './ManagerContent';
import ManagerNavigator from './ManagerNavigator';
import ManagerHeader from './ManagerHeader';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit">
      gametimeplayback.com
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function Paperbase(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [leftNavSelectedId, setLeftNavSelectedId] = React.useState(titles.app.services.leftNavigatorTabs[0]);
  const [membershipsTopNavSelectedId, setMembershipsTopNavSelectedId] = React.useState(titles.app.services.topNavigatorTabs.memberships[0]);
  const [camerasTopNavSelectedId, setCamerasTopNavSelectedId] = React.useState(titles.app.services.topNavigatorTabs.cameras[0]);
  const [playbackTopNavSelectedId, setPlaybackTopNavSelectedId] = React.useState(titles.app.services.topNavigatorTabs.playback[0]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  // admin state items
  const [managerMembersTopNavSelectedId, setManagerMembersTopNavSelectedId] = React.useState(titles.app.services.manager.topNavigatorTabs.members[0]);
  const [managerLeftNavSelectedId, setManagerLeftNavSelectedId] = React.useState(titles.app.services.manager.leftNavigatorTabs[0]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const updateTopSelectedNavItem = (selectedId) => {
    props.updateUser();
    console.log(`update TOP SelectedNavItem in paperbase is ${JSON.stringify(selectedId)} `);
    if(selectedId.leftNav === titles.app.services.leftNavigatorTabs[0]) {
      setMembershipsTopNavSelectedId('clear');
      setMembershipsTopNavSelectedId(selectedId.tab);
    } else if (selectedId.leftNav === titles.app.services.leftNavigatorTabs[1]) {
      setCamerasTopNavSelectedId(selectedId.tab);
    } else if (selectedId.leftNav === titles.app.services.leftNavigatorTabs[2]) {
      setPlaybackTopNavSelectedId(selectedId.tab);
    }
    console.log(`update TOP SelectedNavItem in paperbase is ${JSON.stringify(selectedId)}`)
  };

  const updateLeftSelectedNavItem = (selectedId) => {
    props.updateUser();
    setLeftNavSelectedId(selectedId);
    setMobileOpen(false);
    console.log(`update LEFT SelectedNavItem in paperbase is ${selectedId} with props ${JSON.stringify(props)}`);
    if (firstLoad) {
      console.log('paperbase first load');
      setFirstLoad(false);
      if (selectedId === titles.app.services.leftNavigatorTabs[1]) {
        console.log('setting default top nav item based on left nav for first time clicking specific left nav items');
        setCamerasTopNavSelectedId(titles.app.services.topNavigatorTabs.cameras[0]);
        setPlaybackTopNavSelectedId(titles.app.services.topNavigatorTabs.playback[0]);
      }
    }
  };

  const updateManagerTopSelectedNavItem = (selectedId) => {
    props.updateUser();
    console.log(`update TOP SelectedNavItem in paperbase is ${JSON.stringify(selectedId)} `);
    if(selectedId.leftNav === titles.app.services.manager.leftNavigatorTabs[0]) {
      setManagerMembersTopNavSelectedId('clear');
      setManagerMembersTopNavSelectedId(selectedId.tab);
    } 
    console.log(`update TOP SelectedNavItem in paperbase is ${JSON.stringify(selectedId)}`)
  };

  const updateManagerLeftSelectedNavItem = (selectedId) => {
    props.updateUser();
    setManagerLeftNavSelectedId(selectedId);
    setMobileOpen(false);
    console.log(`update LEFT SelectedNavItem in paperbase is ${selectedId} with props ${JSON.stringify(props)}`);
    if (firstLoad) {
      console.log('paperbase first load');
      setFirstLoad(false);
      if (selectedId === titles.app.services.manager.leftNavigatorTabs[1]) {
        console.log('setting default top nav item based on left nav for first time clicking specific left nav items');
        //setCamerasTopNavSelectedId(titles.app.services.topNavigatorTabs.cameras[0]);
        //setPlaybackTopNavSelectedId(titles.app.services.topNavigatorTabs.playback[0]);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {
            isSmUp ? null : 
            props.userData.type === 'regular' ? 
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                leftNavClicked={updateLeftSelectedNavItem}
              /> :
              <ManagerNavigator 
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                leftNavClicked={updateManagerLeftSelectedNavItem}
              />
          }
          {
            props.userData.type === 'regular' ? 
            <Navigator
              PaperProps={{ style: { width: drawerWidth } } }
              leftNavClicked={updateLeftSelectedNavItem}
              sx={{ display: { sm: 'block', xs: 'none' } }}
            /> :
            <ManagerNavigator 
              PaperProps={{ style: { width: drawerWidth } } }
              leftNavClicked={updateManagerLeftSelectedNavItem}
              sx={{ display: { sm: 'block', xs: 'none' } }}
            />
          }
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {
            props.userData.type === 'regular' ?
            <Header onDrawerToggle={handleDrawerToggle} leftNavSelectedId={leftNavSelectedId} topNavListItemClicked={updateTopSelectedNavItem}/> :
            <ManagerHeader onDrawerToggle={handleDrawerToggle} leftNavSelectedId={managerLeftNavSelectedId} topNavListItemClicked={updateManagerTopSelectedNavItem}/>
          }
          
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
            {
              props.userData.type === 'regular' ? 
              <Content
                sub={props.sub}
                userAttributes={props.user.attributes}
                userData={props.userData}
                membershipsTopNavSelectedId={membershipsTopNavSelectedId} 
                camerasTopNavSelectedId={camerasTopNavSelectedId}
                playbackTopNavSelectedId={playbackTopNavSelectedId}
                leftNavSelectedId={leftNavSelectedId}
                updateUser={props.updateUser}
              /> : 
              <ManagerContent
                sub={props.sub}
                userAttributes={props.user.attributes}
                userData={props.userData}
                managerMembersTopNavSelectedId={managerMembersTopNavSelectedId} 
                managerLeftNavSelectedId={managerLeftNavSelectedId}
                updateUser={props.updateUser}
              /> 
            }
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

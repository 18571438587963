import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from "aws-amplify";
import config from "./config";

// material UI fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.getEnvironmentConfig(config.Environment).cognito.REGION,
    userPoolId: config.getEnvironmentConfig(config.Environment).cognito.USER_POOL_ID,
    identityPoolId: config.getEnvironmentConfig(config.Environment).cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.getEnvironmentConfig(config.Environment).cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "clubs",
        endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayClubs.URL,
        region: config.getEnvironmentConfig(config.Environment).apiGatewayClubs.REGION
      },
      {
        name: "users",
        endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayUsers.URL,
        region: config.getEnvironmentConfig(config.Environment).apiGatewayUsers.REGION
      },
      {
        name: "videos",
        endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayVideos.URL,
        region: config.getEnvironmentConfig(config.Environment).apiGatewayVideos.REGION
      },
      {
        name: "payments",
        endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayPayments.URL,
        region: config.getEnvironmentConfig(config.Environment).apiGatewayPayments.REGION
      },
    ]}
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import titles from '../../../titles.json';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Copyright() {

  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit">
      gametimeplayback.com
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function AppFooter() {
  
  const [openOneTimeModal, setOneTimeModaleOpen] = React.useState(false);
  const handleOneTimeModalClose = () => setOneTimeModaleOpen(false);

  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              { false ? <Grid item sx={{ display: 'flex' }}>
                <Box component="a" href="https://mui.com/" sx={iconStyle}>
                  <img
                    src="/static/themes/onepirate/appFooterFacebook.png"
                    alt="Facebook"
                  />
                </Box>
                <Box component="a" href="https://x.com/MUI_hq" sx={iconStyle}>
                  <img src="/static/themes/onepirate/appFooterTwitter.png" alt="X" />
                </Box>
              </Grid> : null }
              <Grid item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <Link onClick={()=>setOneTimeModaleOpen(true)}>terms and conditions</Link>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Modal
                open={openOneTimeModal}
                onClose={handleOneTimeModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  {
                    <div>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Terms and Conditions
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {titles.app.general.terms}
                      </Typography>
                      <Button onClick={() => {  console.log(`agree`); handleOneTimeModalClose(); }}>Close</Button>
                    </div>
                  }
                </Box>
              </Modal>
            </Box>
            <Copyright />
          </Grid>
          { false ? 
            <Grid item xs={6} sm={8} md={4}>
              <Typography variant="h6" marked="left" gutterBottom>
                Language
              </Typography>
              <TextField
                select
                size="medium"
                variant="standard"
                SelectProps={{
                  native: true,
                }}
                sx={{ mt: 1, width: 150 }}
              >
                {LANGUAGES.map((language) => (
                  <option value={language.code} key={language.code}>
                    {language.name}
                  </option>
                ))}
              </TextField>
          </Grid> : null }
          <Grid item>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useEffect } from 'react';

import titles from '../titles.json';
import SendIcon from '@mui/icons-material/Send';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

const lightColor = 'rgba(255, 255, 255, 0.7)';

function Header(props) {

  const [width, setWidth] = React.useState(window.innerWidth);
  const handleWindowSizeChange = () => {
          setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const { onDrawerToggle } = props;

  const [activeMembersId, updateVisualMembersSelectedId] = React.useState(titles.app.services.manager.topNavigatorTabs.members[0]);
  const [activeCamerasId, updateVisualCamerasSelectedId] = React.useState(titles.app.services.topNavigatorTabs.cameras[0]);
  const [activePlaybackId, updateVisualPlaybackSelectedId] = React.useState(titles.app.services.topNavigatorTabs.playback[0]);
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              {false ? <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                temp link
              </Link>: null }
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                 {false ?  <NotificationsIcon /> : null }
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
               { false ? <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" /> : null }
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {
                  props.leftNavSelectedId === titles.app.services.manager.leftNavigatorTabs[0] ? titles.app.services.manager.leftNavigatorContentTitles[0] : 
                  props.leftNavSelectedId === titles.app.services.manager.leftNavigatorTabs[1] ? titles.app.services.manager.leftNavigatorContentTitles[1] : 
                  props.leftNavSelectedId === titles.app.services.manager.leftNavigatorTabs[2] ? titles.app.services.manager.leftNavigatorContentTitles[2] :
                  props.leftNavSelectedId === titles.app.more.leftNavigatorTabs[0] ? titles.app.more.leftNavigatorContentTitles[0] :
                  props.leftNavSelectedId === titles.app.more.leftNavigatorTabs[1] ? titles.app.more.leftNavigatorContentTitles[1] :
                  'TBD' 
                }
              </Typography>
            </Grid>
            <Grid item>
              {
                width > 768 ?
                <Button
                  onClick={() => {
                    alert('We are working hard on 3rd party integration. Stay tuned');
                  }}
                  sx={{ borderColor: lightColor }}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  endIcon={<SettingsEthernetIcon />}
                >
                  3rd Party Integration
                </Button> : 
                <Button
                  onClick={() => {
                    alert('We are working hard on 3rd party integration. Stay tuned');
                  }}
                  sx={{ borderColor: lightColor }}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  endIcon={<SettingsEthernetIcon />}
                >
                </Button>
              }
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  { false ? <HelpIcon /> : null }
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        {
          props.leftNavSelectedId === titles.app.services.manager.leftNavigatorTabs[0] ? 
          <Tabs value={activeMembersId === titles.app.services.manager.topNavigatorTabs.members[0] ? 0 : activeMembersId === titles.app.services.manager.topNavigatorTabs.members[1] ? 1 : 2} textColor="inherit">
            <Tab 
              label={titles.app.services.manager.topNavigatorTabs.members[0]} 
              onClick={() => {
                props.topNavListItemClicked({leftNav: titles.app.services.manager.leftNavigatorTabs[0], tab: titles.app.services.manager.topNavigatorTabs.members[0]}); 
                updateVisualMembersSelectedId(titles.app.services.manager.topNavigatorTabs.members[0])}
              }
            />
            <Tab 
              label={titles.app.services.manager.topNavigatorTabs.members[1]} 
              onClick={() => {
                props.topNavListItemClicked({leftNav: titles.app.services.manager.leftNavigatorTabs[0], tab: titles.app.services.manager.topNavigatorTabs.members[1]}); 
                updateVisualMembersSelectedId(titles.app.services.manager.topNavigatorTabs.members[1])}
                }
              />
              <Tab 
                label={titles.app.services.manager.topNavigatorTabs.members[2]} 
                onClick={() => {
                  props.topNavListItemClicked({leftNav: titles.app.services.manager.leftNavigatorTabs[0], tab: titles.app.services.manager.topNavigatorTabs.members[2]}); 
                  updateVisualMembersSelectedId(titles.app.services.manager.topNavigatorTabs.members[2])}
                }
              />
          </Tabs> : null
        }
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;

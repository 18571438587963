/*
If you are using server-side rendering, remember that this component should be loaded on client-side
shaka player needs to be loaded on client-side, loading it on server-side may lead to error or undesired results
*/


/*
importing dependencies and CSS file(s) required for UI customization
*/
import React from 'react';
import 'shaka-player/dist/controls.css';
const shaka = require('shaka-player/dist/shaka-player.ui.js');

const squashImage = require('../images/sport_squash2-620x330.jpg');
//Creating class component
class VideoPlayer extends React.PureComponent{

	constructor(props){

		super(props);

		//Creating reference to store video component on DOM
		this.videoComponent = React.createRef();

		//Creating reference to store video container on DOM
		this.videoContainer = React.createRef();

		//Initializing reference to error handlers
		this.onErrorEvent = this.onErrorEvent.bind(this);
		this.onError = this.onError.bind(this);
	}

	onErrorEvent(event) {
	  // Extract the shaka.util.Error object from the event.
	  this.onError(event.detail);
	}

	onError(error) {
	  // Log the error.
	  console.error('Error code', error.code, 'object', error);
	}

	componentDidMount(){

		//Link to MPEG-DASH video
		//var manifestUri = 'https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd';
        var manifestUri = this.props.hlsurl;

		//Getting reference to video and video container on DOM
		const video = this.videoComponent.current;
		const videoContainer = this.videoContainer.current;
        
        // i added and removed videoContainer.classList.add('shaka-mobile');
        
		//Initialize shaka player
		var player = new shaka.Player(video);

        // my config . https://nightly-dot-shaka-player-demo.appspot.com/docs/api/tutorial-config.html
        //https://nightly-dot-shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.PlayerConfiguration
        console.log(`player config ${JSON.stringify(player.getConfiguration())}`);
        //player.configure() - add element is here

        // end my config

		//Setting up shaka player UI
      	const ui = new shaka.ui.Overlay(player, videoContainer, video);
      	ui.getControls();

        // my UI controls https://shaka-player-demo.appspot.com/docs/api/tutorial-ui-customization.html
        //https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.UIConfiguration
        const config = {
            addBigPlayButton: false,
           // addSeekBar: false,
            doubleClickForFullscreen: false,
            enableFullscreenOnRotation: false,
            controlPanelElements: ['play_pause', 'seekBar'],
            seekBarColors: {
                base: 'rgba(255, 255, 255, 0.3)',
                buffered: 'blue',
                played: 'rgb(255, 255, 255)',
                }
            };
          ui.configure(config);

          // end ui control


          
          
		// Listen for error events.
  		player.addEventListener('error', this.onErrorEvent);

  		// Try to load a manifest.
	  	// This is an asynchronous process.
	  	player.load(manifestUri).then(function() {
		    // This runs if the asynchronous load is successful.
		    console.log('The video has now been loaded!');
	  	}).catch(this.onError);  // onError is executed if the asynchronous load fails.

	}

	render(){

		/*
		Returning video with a container. Remember, when setting up shaka player with custom UI, you must
		add your video component inside a container
		The container will be used by shaka player to add your customized UI for the player
		*/
		return(
			<div className="video-container" ref={this.videoContainer}>
				<video 
                    playsInline
					className="shaka-video"
					ref={this.videoComponent}
					poster={squashImage}
				/>
			</div>
		);
	}
}

export default VideoPlayer;

import logo from './logo.svg';
import './App.css';

import config from "./config.js";
import React, { Component, Fragment } from "react";

import VideoEngineShaka from './videoEngineShaka.js';

//material UI
import Button from '@mui/material/Button';

//main auth page
import AuthenticatedPage from './paperbase/Paperbase.js';

// unauth page
import Home from './onepirate/Home.js';

import { API, Auth } from 'aws-amplify';

class App extends Component {

  constructor(props){
    console.log(`constructor of App.js`)
    super(props);
    this.state = {
      auth: false,
      user: undefined,
      userData: undefined
    }
  }

  handleLogoff = async event => {
    //sign out
    await Auth.signOut();
    this.setState({auth: false});
    console.log('signed out');
  }

  updateUser = async event => {
    console.log(`app js updateUserData function`);
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (user !== undefined) {
        console.log(`app js updateUserData API called as we are authenticated`);
        var path = `users`;
        let userData = await API.get("users", path);
        console.log(`users API user data ${JSON.stringify(userData)}`)
        if (userData !== undefined) {
          this.setState({auth: true, user: user, userData: userData});
        }
      } else {
        console.log(`app js updateUserData API not called as not yet authenticated`);
      }
    } catch (e) {
      if (e = 'The user is not authenticated') {
        console.log(`error in update user ${e}`);
        this.setState({auth: false, user: undefined});
      } else {
        console.log(`error in update user ${e}`);
      }
    }
  }

  async componentDidMount() {
    this.updateUser();
  }

  render() {
    return(
      <Home user={this.state.user} userData={this.state.userData !== undefined ? this.state.userData : undefined } sub={this.state.user !== undefined ? this.state.user.attributes.sub : undefined} authenticated={this.state.auth} updateUser={() => this.updateUser()} logoff={() => this.handleLogoff()}/>
    )
  }
}

export default App;

import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';

import { useEffect } from 'react';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function AppAppBar(props) {
  const [activeId, updateVisualSelectedId] = React.useState('Clubs');

  const [width, setWidth] = React.useState(window.innerWidth);
  const handleWindowSizeChange = () => {
          setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);


  // hamburger menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            sx={{ fontSize: 24 }}
          >
            {'GAMETIMEPLAYBACK'}
          </Link>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            {
              props.authenticated === true ? 
              <Link
                variant="h6"
                underline="none"
                onClick={() => props.updateLocation('default')}
                sx={{ ...rightLink, color: 'secondary.main' }}
              >
                {
                  width > 768 ? 
                  <Button 
                      type="submit"
                      color="secondary"
                      variant="contained"
                  >Log Out
                  </Button> : 
                  <LoginIcon color="secondary" sx={{ display: 'block' }} />
                }
              </Link> :
              <div>
                {
                  width > 768 ?
                  <div>
                    <Link
                    variant="h6"
                    underline="none"
                    onClick={() => {props.updateLocation('signup'); updateVisualSelectedId('signup')}}
                    sx={{ ...rightLink, color: activeId === 'signup' ? 'secondary.main' : 'secondary.light' }}
                  >
                    { width <= 768 ? 
                        <LoginIcon color="success" sx={{ display: 'block' }} /> : 
                        <Button type="submit"
                          color="secondary"
                          variant="contained"
                          >Sign Up
                        </Button>
                    }
                  </Link>
                  <Link
                    variant="h6"
                    underline="none"
                    onClick={() => {props.updateLocation('signin'); updateVisualSelectedId('signin')}}
                    sx={{ ...rightLink, color: activeId === 'signin' ? 'secondary.main' : 'secondary.light' }}
                  >
                    { width <= 768 ? 
                          <LoginIcon color="success" sx={{ display: 'block' }} /> : 
                          <Button type="submit"
                            color="secondary"
                            variant="contained"
                            >Sign In
                          </Button>
                      }
                  </Link>
                  </div> : 
                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <MenuIcon color="secondary" sx={{ display: 'block' }} />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => {handleClose(); props.updateLocation('signup'); updateVisualSelectedId('signup')}}>Sign Up</MenuItem>
                      <MenuItem onClick={() => {handleClose(); props.updateLocation('signin'); updateVisualSelectedId('signin')}}>Sign In</MenuItem>
                    </Menu>
                  </div>
                }
              </div>
            }
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
